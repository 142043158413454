<template>
    <el-tabs tab-position="top" style="height: 100%;" v-model="messageTab" @tab-click="handleClick">
        <el-tab-pane name="all">
            <span slot="label">全部消息
                <el-badge :value="unreadCount" size="mini" class="item"></el-badge>
            </span>
            <div v-for="(data,index) in tableData" :key="index" class="text item">
                <div :class="data.isRead ? 'messContentFin' : 'messContent'">
                    {{data.content}}
                </div>
                <div class="messBtnBox">
                    <div class="messTimer">{{formatCreateTime(data.createTime)}}</div>
                    <div :class="data.isRead ? 'messButtonFin' : 'messButton'" @click="viewsMessDetail(data)">查看详情</div>
                </div>
                <!-- <el-row :gutter="20">
                    <el-col :span="24">{{data.content}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="18">{{formatCreateTime(data.createTime)}}</el-col>
                </el-row> -->
            </div>
            <el-pagination v-if="pages.dataCount > 20" background layout="prev, pager, next" @current-change="pageChange" :total="pages.dataCount"
                :page-size="pages.pageSize">
            </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="已读消息" name="readed">
            <div v-for="(data,index) in tableData" :key="index" class="text item">
                <div :class="data.isRead ? 'messContentFin' : 'messContent'">
                    {{data.content}}
                </div>
                <div class="messBtnBox">
                    <div class="messTimer">{{formatCreateTime(data.createTime)}}</div>
                    <div :class="data.isRead ? 'messButtonFin' : 'messButton'" @click="viewsMessDetail(data)">查看详情</div>
                </div>
                <!-- <el-row :gutter="20">
                    <el-col :span="24">{{data.content}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="18">{{formatCreateTime(data.createTime)}}</el-col>
                </el-row> -->
            </div>
            <el-pagination v-if="pages.dataCount > 20" background layout="prev, pager, next" @current-change="pageChange" :total="pages.dataCount"
                :page-size="pages.pageSize">
            </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="未读消息" name="unread">
            <div v-for="(data,index) in tableData" :key="index" class="text item">
                <div :class="data.isRead ? 'messContentFin' : 'messContent'">
                    {{data.content}}
                </div>
                <div class="messBtnBox">
                    <div class="messTimer">{{formatCreateTime(data.createTime)}}</div>
                    <div :class="data.isRead ? 'messButtonFin' : 'messButton'" @click="viewsMessDetail(data)">查看详情</div>
                </div>
                <!-- <el-row :gutter="20">
                    <el-col :span="24">{{data.content}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="18">{{formatCreateTime(data.createTime)}}</el-col>
                </el-row> -->
            </div>
            <el-pagination v-if="pages.dataCount > 20" background layout="prev, pager, next" @current-change="pageChange" :total="pages.dataCount"
                :page-size="pages.pageSize">
            </el-pagination>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
    import {
        getNotificationWebsiteListPage,
        getNotificationWebSiteUnReadCount,
        updateNotificationWebsiteIsRead
    } from '../api/api'
    import util from "../../util/date";
    export default {
        name: "Toolbar",
        data() {
            return {
                searchVal: "", //双向绑定搜索内容
                show: true,
                pages: {
                    pageSize: 20, //默认每页条数
                    pageIndex: 1, //默认进入页
                    dataCount: 0, //默认总页数
                },
                filters: {
                    ddIdTemplateType: 118,
                    isRead: undefined
                },
                listLoading: false,
                tableData: [],
                messageTab: "all",
                unreadCount: 0
            };
        },
        props: ["templateType", "tabName"], //接受父组件传值
        methods: {
            callFunc(item) {
                item.search = this.searchVal;
                this.$emit("callFunction", item); //将值传给父组件
            },
            formatCreateTime: function (date) {
                return !date || date == "" ?
                    "" :
                    util.formatDate.format(new Date(date), "yyyy-MM-dd hh:mm:ss");
            },
            getNotificationWebsiteList() {
                var params = {
                    pageIndex: this.pages.pageIndex,
                    pageSize: this.pages.pageSize,
                    ddIdTemplateType: this.filters.ddIdTemplateType,
                }
                if (this.filters.isRead != undefined) {
                    params.isRead = this.filters.isRead;
                }
                this.listLoading = true
                getNotificationWebsiteListPage(params).then(res => {
                    var result = res.data
                    if (result.success) {
                        this.tableData = result.response.data
                        this.pages.dataCount = result.response.dataCount
                    }
                    this.listLoading = false
                })
            },
            getUnReadCount() {
                var params = {
                    ddIdTemplateType: this.filters.ddIdTemplateType
                }
                getNotificationWebSiteUnReadCount(params).then(res => {
                    var result = res.data
                    if (result.success) {
                        this.unreadCount = result.response
                    }
                })
            },
            pageChange(page) {
                console.log("pageChange:" + page)
                this.pages.pageIndex = page
                this.getNotificationWebsiteList();
            },
            handleClick(tab, event) {
                this.pages.pageIndex = 1
                switch (tab._props.name) {
                    case "all":
                        this.filters.isRead = undefined;                        
                        break;
                    case "readed":
                        this.filters.isRead = true;                      
                        break;
                    case "unread":
                        this.filters.isRead = false;
                        break;
                }
                this.getNotificationWebsiteList();
            },
            // 查看详情
            viewsMessDetail(row) {
                var params = {
                    id: row.id
                }
                updateNotificationWebsiteIsRead(params).then(res => {
                    var result = res.data
                    if(result.success) {
                        if(this.filters.ddIdTemplateType == 118) {
                            var userInfo = JSON.parse(window.localStorage.user)
                            if(userInfo.roleIds.includes(5)) {
                                this.$emit("closeDialog")
                                this.$router.replace({path: "/Task/AcceptTask",query: {id: row.id}});
                            } else {
                                this.$emit("closeDialog",row)
                            }
                        } else {
                            this.$emit("closeDialog",row)
                        }
                    } else {
                        this.$message.error(result.message)
                    }
                })
            }
        },
        mounted() {
            console.log("message:" + this._props.templateType);
            console.log("tabName:" + this._props.tabName);
            switch (this._props.tabName) {
                case "task":
                    this.filters.ddIdTemplateType = 118;
                    break;
                case "collection":
                    this.filters.ddIdTemplateType = 119;
                    break;
                case "warning":
                    this.filters.ddIdTemplateType = 120;
                    break;
            }
            this.getNotificationWebsiteList();
            this.getUnReadCount();
            // if (this._props.isShow == false) {
            //     this.show = this._props.isShow
            // } else {
            //     this.show = true
            // }

        },
        watch: {
            templateType(after, before) {
                console.log("messageafter:" + after);
                console.log("messagebefore:" + before);
                switch (after) {
                    case "task":
                        this.filters.ddIdTemplateType = 118;
                        break;
                    case "collection":
                        this.filters.ddIdTemplateType = 119;
                        break;
                    case "warning":
                        this.filters.ddIdTemplateType = 120;
                        break;
                }
                console.log("ddIdTemplateType:" + this.filters.ddIdTemplateType);
                if (this._props.tabName === after) {
                    this.getNotificationWebsiteList();
                    this.getUnReadCount();
                }
            }
        }
    };
</script>

<style lang="stylus" scoped>
    .text {
        margin: 10px 0;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
    .messBtnBox {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .messTimer {
        color: #999;
        font-size: 14px;
    }
    .messContentFin {
        color: #999;
    }
    .messButton {
        color: #F56C6C;
        border: 1px solid #F56C6C;
        padding: 2px 5px;
        border-radius: 11px;
        cursor: pointer;
    }
    .messButtonFin {
        color: #999;
        border: 1px solid #999;
        padding: 2px 5px;
        border-radius: 11px;
        cursor: pointer;
    }
</style>