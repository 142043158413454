import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';

let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';

// 请求延时
axios.defaults.timeout = 60000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        // if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
        if (storeTemp.state.token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }
        saveRefreshtime();
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // 在用户操作的活跃期内
            if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                return refreshToken({ token: window.localStorage.Token }).then((res) => {
                    if (res.success) {
                        store.commit("saveToken", res.response.token);
                        var curTime = new Date();
                        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                        store.commit("saveTokenExpire", expiredate);
                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = 'Bearer ' + res.response.token;
                        return axios(error.config);
                    } else {
                        ToLogin()
                        return false;
                    }
                })
                .catch((err) => {
                    ToLogin()
                    return false;
                })
            } else {
                ToLogin()
                return false;
            }
        } else if (response.data.status == 403) {
            Vue.prototype.$message({
                message: '失败！该操作无权限',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 429) {
            Vue.prototype.$message({
                message: '刷新次数过多，请稍事休息重试！',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 500) {
            setTimeout(function () {
                Vue.prototype.$message({
                    message: '服务器错误，请稍后重试！',
                    type: 'error'
                });
            }, 1000)
            return null;
        }
        return response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });
            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return refreshToken({ token: window.localStorage.Token }).then((res) => {
                        if (res.success) {
                            // Vue.prototype.$message({
                            //     message: 'refreshToken success! loading data...',
                            //     type: 'success'
                            // });
                            store.commit("saveToken", res.response.token);
                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                            store.commit("saveTokenExpire", expiredate);
                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                            return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                    return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                }
            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function () {
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                }, 1000)
                return null;
            }
        }
        return ""; // 返回接口返回的错误信息
    }
);
const ToLogin = params => {
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');
    var routerGo = router.currentRoute.fullPath.substring(0, 6)
    if (routerGo == "/login") {
    } else {
        router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
        });
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });
    // 调试登录过期后重新登录第一次无法跳转到相应的页面，而是停留在登录页面，所以暂时注释，怀疑重新加载所以看不到console.log("redirect_api--") date:2020-12-23
    window.location.reload()
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};
export const BaseApiUrl = base;
export const saveRefreshtime = params => {
    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))
    let refreshCount = 1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime = nowtime > expiretime ? nowtime : expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    } else {
        window.localStorage.refreshtime = new Date(-1);
    }
};

// 系统登录
export const requestLogin = params => {
    return axios.post(`${base}/omswebapi/login/login`, params).then(res => res.data);
};

// 获取登录短信验证码
export const getLoginCode = params => {
    return axios.get(`${base}/omswebapi/login/getLoginCode`, { params: params }).then(res => res.data);
};

// 短信验证码登录
export const codelogin = params => {
    return axios.get(`${base}/omswebapi/login/codelogin`, { params: params }).then(res => res.data);
};

//刷新token
export const refreshToken = params => {
    return axios.get(`${base}/omswebapi/login/refreshToken`, { params: params }).then(res => res.data);
};

// ==============================首页接口STAR======================================================================
//首页
//获取报名人数的图表数据
export const examenrollCountGroupByDate = params => {
    return axios.get(`${base}/omswebapi/Home/ExamenrollCountGroupByDate`, { params: params });
};
//根据行政区获取报名人数的图表数据
export const examenrollCountGroupByDateByID = params => {
    return axios.get(`${base}/omswebapi/Home/ExamenrollCountGroupByDateByID`, { params: params });
};

//获取考试项目报名人数统计
export const examenrollCountGroupByProjectName = params => {
    return axios.get(`${base}/omswebapi/Home/ExamenrollCountGroupByProjectName`, { params: params });
};
//根据行政区获取考试项目报名人数统计
export const examenrollCountGroupByProjectNameByID = params => {
    return axios.get(`${base}/omswebapi/Home/ExamenrollCountGroupByProjectNameByID`, { params: params });
};

// 社会单位总数及学习中数量
export const getUnitsAndCertificatesResponse = params => {
    return axios.get(`${base}/omswebapi/Home/getUnitsAndCertificatesResponse`, { params: params });
};

// 下级社会单位总数及学习中数量
export const getUnitsAndCertificatesResponseByID = params => {
    return axios.get(`${base}/omswebapi/Home/getUnitsAndCertificatesResponseByID`, { params: params });
};

// 下级城市/地区的社会单位、持证总数、证书达标数列表
export const getProportionCertificateHolders = params => {
    return axios.get(`${base}/omswebapi/Home/GetProportionCertificateHolders`, { params: params });
};

// 下级城市/地区的持证总数统计
export const getProportionCertificateHoldersByID = params => {
    return axios.get(`${base}/omswebapi/Home/GetProportionCertificateHoldersByID`, { params: params });
};

// 社会单位持证统计分析
export const getSocialUnitCertificateHoldersStatistics = params => {
    return axios.get(`${base}/omswebapi/Home/GetSocialUnitCertificateHoldersStatistics`, { params: params });
};

// 待监管单位
export const getHomeSocialUnitStatistics = params => {
    return axios.get(`${base}/omswebapi/Home/GetHomeSocialUnitStatistics`, { params: params });
};

// 地图滑上社会单位，学习中，取证，待监管数量
export const getUnitsAndCertificatesSingleResponseByID = params => {
    return axios.get(`${base}/omswebapi/Home/getUnitsAndCertificatesSingleResponseByID`, { params: params });
};

//首页改版新接口
// 共用-获取地图上方滑上浮窗数据
export const getStudentLearnRecords = params => {
    return axios.get(`${base}/omswebapi/Home/GetStudentLearnRecords`, { params: params });
};
// 共用-获取地图地标接口
export const getJsonFile = params => {
    return axios.get(`${base}/omswebapi/GeoJson/GetJsonFile`, { params: params });
};



//系统管理
//用户管理
export const getUserListPage = params => {
    return axios.get(`${base}/omswebapi/userInfo/getUserInfoListPage`, { params: params });
};

// 根据token获取用户详细信息
export const getUserByToken = params => {
    return axios.get(`${base}/omswebapi/userInfo/getUserInfoByToken`, { params: params }).then(res => res.data);
};

//新增用户信息
export const addUserInfo = params => {
    return axios.post(`${base}/omswebapi/userInfo/addUserInfo`, params);
};

//更新用户信息
export const updateUserInfo = params => {
    return axios.post(`${base}/omswebapi/userInfo/updateUserInfo`, params);
};

//删除用户信息
export const deleteUserInfo = params => {
    return axios.delete(`${base}/omswebapi/userInfo/deleteUserInfo`, { params });
};

//发送修改密码手机短信验证码
export const sendModifyPasswordSMSVerificationCode = params => {
    return axios.post(`${base}/omswebapi/SystemManager/SendModifyPasswordSMSVerificationCode`, params);
};

//修改用户登录密码
export const modifyUserPassword = params => {
    return axios.post(`${base}/omswebapi/userInfo/modifyUserPassword`, params);
};

//系统管理
//菜单权限管理

//根据用户ID获取路由树
export const getNavigationBar = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getNavigationBar`, { params: params }).then(res => res.data);
};

//获取菜单树
export const getMenuTree = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getMenuTree`, { params: params });
};

//获取菜单权限树形列表
export const getMenuPermissionsTree = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getMenuPermissionsTree`, { params: params });
};

//新增菜单权限信息
export const addMenuPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/addMenuPermissionsInfo`, params);
};

//更新菜单权限信息
export const updateMenuPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/updateMenuPermissionsInfo`, params);
};

//删除菜单权限信息
export const deleteMenuPermissionsInfo = params => {
    return axios.delete(`${base}/omswebapi/menuPermissions/deleteMenuPermissionsInfo`, { params: params });
};

//保存菜单权限分配
export const assignMenuPermissions = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/assignMenuPermissions`, params);
};

//通过角色获取菜单【无权限】
export const GetAssignMenuPermissionIdByRoleId = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getAssignMenuPermissionIdByRoleId`, { params: params });
};

//系统管理
//角色管理

//根据组织结构ID获取角色信息集合
export const getRoleInfosByOrganizationInfoID = params => {
    return axios.get(`${base}/omswebapi/roleInfo/getRoleInfoByName`, { params: params });
};

//获取角色信息分页列表
export const getRoleListPage = params => {
    return axios.get(`${base}/omswebapi/roleInfo/getRoleInfoListPage`, { params: params });
};

//新增角色信息
export const addRoleInfo = params => {
    return axios.post(`${base}/omswebapi/roleInfo/addRoleInfo`, params);
};

//更新角色信息
export const updateRoleInfo = params => {
    return axios.post(`${base}/omswebapi/roleInfo/updateRoleInfo`, params);
};

//删除角色信息
export const deleteRoleInfo = params => {
    return axios.delete(`${base}/omswebapi/roleInfo/deleteRoleInfo`, { params: params });
};

//系统管理
// 部门管理

//获取组织结构树
export const getOrganizationTree = params => {
    return axios.get(`${base}/omswebapi/departmentInfo/getDepartmentTree`, { params: params });
};

//新增组织结构信息
export const addOrganizationInfo = params => {
    return axios.post(`${base}/omswebapi/departmentInfo/addDepartmentInfo`, params);
};

//更新组织结构信息
export const updateOrganizationInfo = params => {
    return axios.post(`${base}/omswebapi/departmentInfo/updateDepartmentInfo`, params);
};

//删除组织结构信息
export const deleteOrganizationInfo = params => {
    return axios.delete(`${base}/omswebapi/departmentInfo/deleteDepartmentInfo`, { params: params });
};

//系统管理
// 接口管理

//获取接口信息树形列表
export const getApiModulesInfoTree = params => {
    return axios.get(`${base}/omswebapi/apiModulesInfo/getApiModulesInfoTree`, { params: params });
};

//获取接口菜单树
export const getApiMenuTree = params => {
    return axios.get(`${base}/omswebapi/apiModulesInfo/getApiMenuTree`, { params: params });
};

//新增接口信息
export const addApiModulesInfo = params => {
    return axios.post(`${base}/omswebapi/apiModulesInfo/addApiModulesInfo`, params);
};

//更新接口信息
export const updateApiModulesInfo = params => {
    return axios.post(`${base}/omswebapi/apiModulesInfo/updateApiModulesInfo`, params);
};

//删除接口信息
export const deleteApiModulesInfo = params => {
    return axios.delete(`${base}/omswebapi/apiModulesInfo/deleteApiModulesInfo`, { params: params });
};

//系统管理
// 数据权限管理

//获取全部数据权限信息列表
export const getAllDataPermissionsInfoList = params => {
    return axios.get(`${base}/omswebapi/dataPermissionsInfo/getAllDataPermissionsInfoList`, { params: params });
};

//新增数据权限信息
export const addDataPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/addDataPermissionsInfo`, params);
};

//更新数据权限信息
export const updateDataPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/updateDataPermissionsInfo`, params);
};

//删除数据权限信息
export const deleteDataPermissionsInfo = params => {
    return axios.delete(`${base}/omswebapi/dataPermissionsInfo/deleteDataPermissionsInfo`, { params });
};

//保存数据权限分配
export const assignDataPermissions = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/assignDataPermissions`, params);
};

//通过角色ID获取数据权限菜单【无权限】
export const getAssignDataPermissionIdByRoleId = params => {
    return axios.get(`${base}/omswebapi/dataPermissionsInfo/getAssignDataPermissionIdByRoleId`, { params: params });
};

//通用设置
//审批流程管理

//获取审批流程信息分页列表
export const getApprovalProcessListPage = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetApprovalProcessListPage`, { params: params });
};

//新增审批流程
export const addApprovalProcess = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddApprovalProcess`, params);
};

//更新审批流程
export const updateApprovalProcess = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateApprovalProcess`, params);
};

//根据审批流程ID 获取审批流程图
export const getApprovalProcessDiagramByApprovalProcessID = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetApprovalProcessDiagramByApprovalProcessID`, { params: params });
};

//通用设置
//展示信息

//获取展示信息分页集合
export const getShowInformationListPage = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetShowInformationListPage`, { params: params });
};

//新增展示信息
export const addShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddShowInformation`, params);
};

//更新展示信息
export const updateShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateShowInformation`, params);
};

//删除展示信息
export const deleteShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteShowInformation`, params);
};

//顶置/取消顶置展示信息
export const setTopShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/SetTopShowInformation`, params);
};

//通用设置
//轮播信息

//获取轮播信息分页集合
export const getCarouselInformationList = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetCarouselInformationList`, { params: params });
};

//新增轮播信息
export const addCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddCarouselInformation`, params);
};

//更新轮播信息
export const updateCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateCarouselInformation`, params);
};

//删除轮播信息
export const deleteCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteCarouselInformation`, params);
};
// 省市区
export const getAreaDictionaryListWithCode = params => {
    return axios.get(`${base}/omswebapi/areaDictionary/getAreaDictionaryListWithCode`, { params: params });
};

//通用设置
//数据字典信息

//获取数据字典信息列表
export const getDataDictionaryList = params => {
    return axios.get(`${base}/omswebapi/dataDictionary/getById`, { params: params });
};

//新增数据字典信息
export const addDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddDataDictionary`, params);
};

//更新数据字典信息
export const updateDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateDataDictionary`, params);
};

//删除数据字典信息
export const deleteDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteDataDictionary`, params);
};
// 根据命名空间获取数据字典
export const getByNamespace = params => {
    return axios.get(`${base}/omswebapi/dataDictionary/getByNamespace`, { params: params });
};

//通用设置
//行政区域
//获取行政区信息列表
export const getAreaDictionaryList = params => {
    return axios.get(`${base}/omswebapi/areaDictionary/getAreaDictionaryList`, { params: params });
};
//新增行政区信息
export const addAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/AddAreaDictionary`, params);
};

//删除行政区信息
export const deleteAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/DeleteAreaDictionary`, params);
};

//抓取行政区信息
export const grabAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/GrabAreaDictionary`, params);
};

// 新增客户端版本
export const addAppVersion = (req) => {
    return axios.post(`${base}/omswebapi/appVersion/addAppVersion`, req, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

// 删除客户端版本
export const deleteAppVersion = (params) => {
    return axios.delete(`${base}/omswebapi/appVersion/deleteAppVersion`, { params: params });
}

// 获取客户端版本分页列表
export const getAppVersionList = (params) => {
    return axios.get(`${base}/omswebapi/appVersion/getAppVersionList`, { params: params });
}

// 获取客户端版本分页列表
export const updateAppVersion = (params) => {
    return axios.post(`${base}/omswebapi/appVersion/updateAppVersion`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
//鉴定点类型管理

//职业等级方向
export const getOproomProfessionDirList = (params) => {
    return axios.get(`${base}/omswebapi/oproomProfessionDir/getOproomProfessionDirList`, { params: params });
}
//添加
export const addOproomProfessionDir = params => {
    return axios.post(`${base}/omswebapi/oproomProfessionDir/addOproomProfessionDir`, params);
};
//删除
export const updateOproomProfessionDir = params => {
    return axios.post(`${base}/omswebapi/oproomProfessionDir/updateOproomProfessionDir`, params);
};

//职业功能管理
export const getOproomProfessionFuncList = (params) => {
    return axios.get(`${base}/omswebapi/oproomProfessionFunc/getOproomProfessionFuncList`, { params: params });
}
//新增职业技能
export const addOproomProfessionFunc = params => {
    return axios.post(`${base}/omswebapi/oproomProfessionFunc/addOproomProfessionFunc`, params);
};
//编辑职业技能
export const updateOproomProfessionFunc = params => {
    return axios.post(`${base}/omswebapi/oproomProfessionFunc/updateOproomProfessionFunc`, params);
};

//考位类型管理
//获取当前数据列表
export const getExamSeatTypeList = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExamSeatTypeList`, { params: params });
}
//添加考位类型
export const addExamSeatType = params => {
    return axios.post(`${base}/omswebapi/opRoom/addExamSeatType`, params);
};
//编辑
export const updateExamSeatType = params => {
    return axios.post(`${base}/omswebapi/opRoom/updateExamSeatType`, params);
};

//考位设置
//获取当前数据列表
export const getExamSeatExtraDurationList = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExamSeatExtraDurationList`, { params: params });
}
//根据条件获取鉴定点时间
export const getExtraDurationByCondition = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExtraDurationByCondition`, { params: params });
}
//添加
export const addExamSeatExtraDuration = params => {
    return axios.post(`${base}/omswebapi/opRoom/addExamSeatExtraDuration`, params);
};
//编辑
export const updateExamSeatExtraDuration = params => {
    return axios.post(`${base}/omswebapi/opRoom/updateExamSeatExtraDuration`, params);
};

//监控考位
//获取当前数据列表
export const getExamSeatUseDetail = (params) => {
    return axios.get(`${base}/omswebapi/score/getExamSeatUseDetail`, { params: params });
}
//维护考位 重置按钮
export const resetExamSeat = params => {
    return axios.post(`${base}/omswebapi/opRoom/resetExamSeat`, params);
};

//组卷规则管理
//获取当前数据列表
export const getExamPaperCompRulesList = (params) => {
    return axios.get(`${base}/omswebapi/examPaperCompRules/getExamPaperCompRulesList`, { params: params });
}
//检查是否存在正在使用的规则
export const checkIsExistUsingRules = (params) => {
    return axios.get(`${base}/omswebapi/examPaperCompRules/checkIsExistUsingRules`, { params: params });
}
//根据ID获取组卷规则详情
export const getRulesDetailById = (params) => {
    return axios.get(`${base}/omswebapi/examPaperCompRules/getRulesDetailById`, { params: params });
}
//添加
export const addExamPaperCompRules = params => {
    return axios.post(`${base}/omswebapi/examPaperCompRules/addExamPaperCompRules`, params);
};
//编辑
export const updateExamPaperCompRules = params => {
    return axios.post(`${base}/omswebapi/examPaperCompRules/updateExamPaperCompRules`, params);
};

//题干管理
//获取题干管理分页列表
export const getExamQuestionList = (params) => {
    return axios.get(`${base}/omswebapi/examQuestion/getExamQuestionList`, { params: params });
}
//根据鉴定点id获取题干列表
export const getExamQuestionsByPointId = (params) => {
    return axios.get(`${base}/omswebapi/examQuestion/getExamQuestionsByPointId`, { params: params });
}
//新增题干
export const addExamQuestions = params => {
    return axios.post(`${base}/omswebapi/examQuestion/addExamQuestions`, params);
};
//更新题干
export const updateExamQuestion = params => {
    return axios.post(`${base}/omswebapi/examQuestion/updateExamQuestion`, params);
};
//根据职业等级方向id获取鉴定点个数
export const getPointListByProDir = (params) => {
    return axios.get(`${base}/omswebapi/examIdentifyPoint/getPointListByDir`, { params: params });
}

//鉴定点管理
//获取当前数据列表
export const getExamIdentifyPointList = (params) => {
    return axios.get(`${base}/omswebapi/examIdentifyPoint/getExamIdentifyPointList`, { params: params });
}
//新增
export const addExamIdentifyPoint = params => {
    return axios.post(`${base}/omswebapi/examIdentifyPoint/addExamIdentifyPoint`, params);
}
//编辑
export const updateExamIdentifyPoint = params => {
    return axios.post(`${base}/omswebapi/examIdentifyPoint/updateExamIdentifyPoint`, params);
}
//根据考位类型获取鉴定点个数
export const getExamPointNumByExamSeatType = (params) => {
    return axios.get(`${base}/omswebapi/examIdentifyPoint/getExamPointNumByExamSeatType`, { params: params });
}


//设备管理
//考位管理

//获取考位类型分页列表

//获取考位分页列表
export const getExamSeatList = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExamSeatList`, { params: params });
}
//新增考位
export const addExamSeat = params => {
    return axios.post(`${base}/omswebapi/opRoom/addExamSeat`, params);
}
//编辑
export const updateExamSeat = params => {
    return axios.post(`${base}/omswebapi/opRoom/updateExamSeat`, params);
}
//批量生成考位
export const addBatchExamSeat = params => {
    return axios.post(`${base}/omswebapi/opRoom/addBatchExamSeat`, params);
}

//考试模式管理
//获取考试模式列表
export const getExamModelTypeList = (params) => {
    return axios.get(`${base}/omswebapi/examModelType/getExamModelTypeList`, { params: params });
}

//成绩管理
//当前数据列表
export const getExamScoreList = (params) => {
    return axios.get(`${base}/omswebapi/score/getExamScoreList`, { params: params });
}
//详情
export const getExamDetailByExamId = (params) => {
    return axios.get(`${base}/omswebapi/score/getExamDetailByExamId`, { params: params });
}
//上传视频
export const uploadVideoFile =`${base}/omswebapi/videoManage/uploadVideoFile`

//视频管理
//获取当前数据列表
export const getExamIdentifyPointVideoList = (params) => {
    return axios.get(`${base}/omswebapi/omswebapi/examIdentifyPointVideo/getExamIdentifyPointVideoList`, { params: params });
}
//新增
export const addExamIdentifyPointVideo = params => {
    return axios.post(`${base}/omswebapi/examIdentifyPointVideo/addExamIdentifyPointVideo`, params);
}
//编辑
export const updateExamIdentifyPointVideo = params => {
    return axios.post(`${base}/omswebapi/examIdentifyPointVideo/updateExamIdentifyPointVideo`, params);
}

//获取js上传视频参数
export const getVideoUploadParams = (params) => {
    return axios.get(`${base}/omswebapi/videoManage/getVideoUploadParams`, { params: params });
}

//新增视频编码
export const addVideoByCode = params => {
    return axios.post(`${base}/omswebapi/videoManage/addVideoByCode`,  params );
}

//
export const getWechatLoginUrl = (params) => {
    return axios.get(`${base}/omswebapi/login/getWechatLoginUrl`, { params: params });
}
// 修改站内通知记录已读状态
export const updateNotificationWebsiteIsRead = params => {
    return axios.post(`${base}/omswebapi/notificationWebsite/updateNotificationWebsiteIsRead`, params);
}
//更新行政区信息
export const updateAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateAreaDictionary`, params);
};
//工作流管理
//审批
export const approve = params => {
    return axios.post(`${base}/omswebapi/WorkFlow/Approve`, params);
};