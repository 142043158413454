<template>
	<!-- <div style="height:790px;background:#39B3FF;"> -->
	<div style="height: 99%;">
	
<!--			<div id="login_container" style="text-align: center;" v-if="boxWelcomeStatus"></div>-->
			<!-- <div @click="backWelcome()" style="cursor: pointer;" v-else>
				<img style="padding-left: 15px;margin-top:7px;" src="../assets/quit.png" />
			</div> -->
			<!-- <div>社会消防安全教育培训管理系统</div> -->
		
		<div style="text-align: center;width: 80%" v-if="boxWelcomeStatus">
			<iframe style="margin-top: 5% ;width: 30%;height: 600px" :src="qrcodeUrl"  />
<!--			<img width="100" height="200" :src="qrcodeUrl" alt=""/>-->
			<div>请使用微信扫码绑定</div>
		</div>
		
	
		<!-- <div v-else style="position: relative;" :style="{height:pageHeight + 'px'}"> -->
		<div v-else style="position: relative;height: 100%;">
			<img src="../assets/bg.png" alt="" style="width: 100%;height: 100%;">
			<div style="width: 97%;margin: 0 auto;">
				<el-row>
					<el-col :span="6">
					</el-col>
					<el-col :span="12">
					</el-col>
					<el-col :span="6">
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>

import { getWechatLoginUrl } from '../api/api'

export default {
	name: "Welcome",
	data() {
		return {
			qrcodeUrl:undefined,
			pageHeight: 830,
			tableHeight1: 318,
			tableHeight2: 357,
			mapHeight: 520,
			unitBoxHeight: 380,
			boxWelcomeStatus: false,
			knowTableData: [],
			tbaleData1: [],
			tbaleData2: [],
			tbaleData3: [],
			isShow: false,
			files: {
				SocialUnit: 0,
				learningSocialUnit: 0,
				btainCertificate: 0,
				supervisionUnit: 0
			},
			baseData: {
				isShowPie: true,
				isShowTable1: false,
				isShowTable2: true,
				isShowTable3: false,
				cityName: '',
			},
			guangdongData: [],
			slipupMapArr: [],
			userDataParams: '',
			// 地图底部切换按钮值
			buttonActive: 0,
			// 各个分块的提示标题信息
			leftTopeLineTitle: '每日报考人数',
			leftBottomBarTitleOne: '待监管单位数',
			leftBottomBarTitleTwo: '单位数量',
			rightTableTitle: '下辖区域概况',
			rightTableOneTitle: '下辖区域概况',
			middleTopPieceTitle: {
				PieceTitleOne: '应训人数（人）',
				PieceTitleTwo: '报考人数（人）',
				PieceTitleThree: '参训人数（人）',
				PieceTitleFour: '持证数量',
			},
			middleMapTitle: {
				mapTitleOne: '人员参训率<30%',
				mapTitleTwo: '30%≤人员参训率≤70%',
				mapTitleThree: '人员参训率>70%',
			},
			rightTopPieTitle: '机构培训情况',
			// 待监管单位/单位数量
			dataType: "1",
			// 浮窗
			floatPostWinArr: [
				{name: "安全管理人，责任人",value: 2200},
				{name: "单位员工",value: 2255},
				{name: "中小企业",value: 2265},
			],
			floatIndustrytWinArr: [
				{name: "高层办公建筑",value: 2200},
				{name: "医疗机构",value: 2255},
				{name: "文管系统",value: 2265},
			],
			mapColor1: '#13b5b1',
			mapColor2: '#ca505b',
			isHiddenMap: true,
			tableAverage: 0,
			isShowBarChart: true,
			isShowNavBar: 10000,
			GeoJson: '',
			unitFirer: 0,
			stationFirer: 0,
		}
	},
	methods: {
	
	},
	beforeMount() {
		var pageHeight = document.getElementById('app').offsetHeight - 60 - 36 - 20
		this.tableHeight1 = (pageHeight * 0.45) - 32
		this.tableHeight2 = (pageHeight * 0.5) - 32
		this.pageHeight = pageHeight
		this.mapHeight = pageHeight - 210
		// const script = document.createElement('script')
		// script.type = 'text/javascript'
		// script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
		// document.body.appendChild(script)
	},
	mounted() {
		var _this = this
		var curTime = new Date();
		if (window.localStorage.TokenExpire) {
			var expiretime = new Date(Date.parse(window.localStorage.TokenExpire));
			if (curTime >= expiretime) {
				this.$router.push("/login");
				return
			}
		} else {
			this.$router.push("/login");
			return
		}
		var userData = JSON.parse(window.localStorage.user)
		if(userData.TownID > 0) {
			this.baseData.isShowTable1 = true
			this.baseData.isShowTable2 = false
			this.baseData.isShowPie = false
		}
		let params = {}
		getWechatLoginUrl(params).then(res=>{
			// console.log(res,'reswechai')
			if (res.data.success){
				if (res.data.response.qrCodeUrl){
					this.boxWelcomeStatus = true;
					// QRCode.toDataURL(res.data.response.qrCodeUrl)
					// 		.then(url => {
					this.qrcodeUrl = res.data.response.qrCodeUrl;
					// 		})
					// 		.catch(error => {
					// 			console.error('生成二维码失败', error);
					// 		});
					// this.boxWelcomeStatus = true;
					// var obj = new WxLogin({
					// 	self_redirect: false,    //默认为false(保留当前二维码)  true(当前二维码所在的地方通过iframe 内跳转到 redirect_uri)
					// 	id: "login_container",  //容器的id
					// 	appid: "wxa3fdea5a3090f",  //应用唯一标识，在微信开放平台提交应用审核通过后获得
					// 	scope: "snsapi_login",   //应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
					// 	redirect_uri: "https://www.xiang.com//client/index.html",    //扫完码授权成功跳转到的路径
					// 	// state: "",    //用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止 csrf 攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加 session 进行校验
					// 	style: "white",   //提供"black"、"white"可选，默认为黑色文字描述
					// 	href: "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg=="  //自定义样式链接，第三方可根据实际需求覆盖默认样式
					// });
				}
			}
		})
		// setTimeout(() => {
		// 	_this.unifiedRequestAPIfun()
		// })
	},
};
</script>

<style lang="stylus" scoped>
	.boxWelcomeTitle {
		display: flex;
		justify-content: space-between;
		margin-top: -20px;
		background: rgba(6, 23, 64, 1)!important;
		font-size: 20px;
		color: #39B3FF;
		line-height: 40px;
	}
	.tableTopBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(6, 23, 64, 0.7)!important;
		padding-top: 4px;
		height: 29px;
	}
	.tableTitle {
		color #fff;
		font-size: 14px;
		padding-left: 3px;
		cursor: pointer;
	}
	.tableTopBox img{
		width: 30px;
    	height: 30px;
		padding-right: 3px;
		cursor: pointer;
	}
	.tableClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.tableClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body tbody .el-table__row td .cell .el-tag {
		background-color: rgba(6, 23, 64, 0.7)!important;
		border-color: #409EFF!important;
	}
	.tableClass>>>td, .tableClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body-wrapper,.tableClass>>>.is-scrolling-none {
		// padding-right: 20px;
	}
	.tableClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	.knowRateClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagWarning {
		background-color: #ffc760!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagDanger {
		background-color: #d62c2c!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagBlue {
		background-color: #00b6b2!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>td, .knowRateClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	// 鼠标滑上表格行颜色的变化
	.knowRateClass>>>.el-table__body tr:hover>td {
		background-color: #eee!important;
		color: rgba(6, 23, 64, 0.7)!important;
	}
	.centerViews {
		display: flex;
    	justify-content: space-around;
    	align-items: center;
		height: 140px;
	}
	.viewsBox {
		width: 250px;
    	height: 100px;
		background: rgba(6, 23, 64, 0.7);
		display: flex;
		justify-content: space-around;
		border: 1px dashed #47e0e0
	}
	.viewsBox div {
		display: flex;
		flex-direction: column;
		line-height: 36px;
		justify-content: center;
	}
	.boxTitle {
		font-size: 14px;
		color: #fff;
	}
	.boxViewNum {
		font-size: 26px;
		font-weight: bold;
		color: #47E0E0;
	}
	#knowRate {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}
	#lineTable {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#pieTable,#trainingSituation {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#StyloliticTable,#sortTable,#unitParentBox {
		border-right: 1px solid #47e0e0;
		border-left: 1px solid #47e0e0;
		border-bottom: 1px solid #47e0e0;
	}
	.table1 {
		border-top: 1px solid #47e0e0;
	}
	.buttonBox {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonBoxCopy {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonVies {
		width: 110px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		color: #fff;
		border: 1px solid #47e0e0;
		margin: 0 7px;
		cursor: pointer;
		user-select: none;
		transform: skewx(-15deg);
		display: flex;
    	justify-content: center;
		background: rgba(6, 23, 64, 1);
	}
	.buttonVies div {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonVies span {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonActive {
		color: #ffdd77;
	}
	#unitParentBox>>>.el-radio {
		margin-right: 5px;
	}
	#unitParentBox>>>.el-radio__label {
		padding-left: 3px;
	}
	.floatBoxTitle {
		font-size: 15px;
    	font-weight: 600;
		color: #47e0e0;
	}
	.floatingBox {
		display: flex;
		justify-content: flex-start;
		line-height: 24px;
	}
	.floatBoxLeft {
		width: 200px;
		color: #47e0e0;
	}
	.floatBoxRight {
		color: #47e0e0;
	}
	.topBoxAverage {
		width: 26px;
		height: 24px;
		line-height: 24px;
		border: 1px solid;
		border-radius: 18px;
		font-size: 12px;
		margin-bottom: 2px;
	}
</style>
